import { axiosConfig } from './axios-config.js'

// 機構設定
export const line = {
  // LINE 推播 登入
  lineOauth: token => axiosConfig.get('/api/line/oauth', token),

  // LINE 登入後 token 登錄系統
  lineIssue: (data, token) => axiosConfig.post('/api/line/issue', data, token),

  // 取得授權清單
  lineAuth: token => axiosConfig.get('/api/line', token)
}
